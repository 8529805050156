<template>
  <div class="extremeSpeed">
    <div
      class="header"
      v-if="url != 'shoppingMallS' && url != 'orderS' && url != 'downloadS'"
    >
      <div class="nav">
        <div
          class="nav-item"
          :class="{ 'is-active': currentIndex == index }"
          v-for="(item, index) in menuList"
          :key="item.id"
          @click="switchPage(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <router-view ref="routerS"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      url: '',
      menuList: [
        {
          name: '专业文库',
          url: 'libraryS',
        },
        {
          name: '优质模板',
          url: 'templateS',
        },
        {
          name: '闪亮金句',
          url: 'goldenSentenceS',
        },
        // {
        //   name: 'AI成文',
        //   url: 'Oneclickwriting',
        // },
        // {
        //   name: "商城",
        //   url: "shoppingMallS",
        // },
        // {
        //   name: "订单中心",
        //   url: "orderS",
        // },
        // {
        //   name: "下载",
        //   url: "downloadS",
        // },
      ],
    }
  },

  methods: {
    switchPage(item, index) {
      this.currentIndex = index
      this.$router.push(item.url)
    },
  },
  mounted() {},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.url = to.name
        this.menuList.forEach((item, index) => {
          if (to.name == item.url) {
            this.currentIndex = index
          }
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.extremeSpeed {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;
  background-color: #fff;
  padding-bottom: 40px;

  .header {
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    height: 48px;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;

    .nav {
      width: 1350px;
      margin: 0 auto;
      display: flex;
      align-items: center;

      .nav-item {
        cursor: pointer;
        color: #999999;
        font-size: 12px;
        margin: 0 10px;
        padding: 7px 12px;
        cursor: pointer;
      }
      .is-active {
        background: linear-gradient(90deg, #ff6500, #ff9c00);
        border-radius: 12px;
        color: #ffffff;
      }
    }
  }
}
</style>
